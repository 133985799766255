import { ColorTheme } from 'styles/colorThemes/colorThemeTypes';

export const darkTheme: ColorTheme = {
  primary: '#b9b9b9',
  secondary: '#151418',
  greyPrimary: '#1e1e21',
  greySecondary: '#ccc',
  interactiveHiglightInactive: 'rgba(207, 207, 207, 0)',
  interactiveHiglightHover: 'rgba(207, 207, 207, 0.8)',
  interactiveHiglightActive: 'rgba(207, 207, 207, 1)',

  // brinkbit design theme
  brinkbitBlue: '#60aecc',
  brinkbitPrimary: '#a6e4ff',
  brinkbitSecondary: '#050f17',
  brinkbitGreyPrimary: '#050f17',
  brinkbitGreySecondary: '#132a40',

  // spark design theme
  sparkPrimary: '#bbcbd4',
  sparkSecondary: '#e3e8ea',
  sparkGreyPrimary: '#6e8592',
  sparkGreySecondary: '#040708',
  sparkGreyTertiary: '#000',
  sparkGradient1: '#e08e26',
  sparkGradient2: '#b84a21',
  sparkGradient3: '#c25823',
  sparkGradient4: '#bb4d1e',
  sparkGradient5: '#a82f11',
  sparkGradient6: '#941103',
  sparkGradient7: '#731001',
  sparkGradient8: '#9e1708',
  sparkGreyGradient1: '#a8a8a8',
  sparkGreyGradient2: '#666',
  sparkGreyGradient3: '#828282',
  sparkGreyGradient4: '#777',
  sparkGreyGradient5: '#595959',
  sparkGreyGradient6: '#363636',
  sparkGreyGradient7: '#474747',
  sparkGreyGradient8: '#707070',
  sparkMonoOrange: '#bc4729',
  sparkMonoOrangeDesaturated: '#bcbcbc',
  sparkMonoRed: '#9a3322',
  sparkMonoRedDesaturated: '#9a9a9a',
  sparkMonoYellow: '#d2892d',
  sparkMonoYellowDesaturated: '#d2d2d2',

  // hitchd design theme
  hitchdPink: '#fe5e73',
  hitchdOrange: '#ff8d73',
  hitchedLargeColorFill: '#ef3c54',
  hitchdSecondary: '#efd4d4',
  hitchdPinkDesaturated: '#c4c4c4',
  hitchdGreyPrimary: '#231b1c',
};
